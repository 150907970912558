@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.mainContainer {
  scroll-behavior: smooth;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
}
.wrapper {
  width: 100vw;
  height: auto;
  background-color: rgba(192, 192, 192, 0.014);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.videoBg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}
.rows {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8rem auto;
}
.rows h1 {
  color: #302e4d;
  font-family: "Open Sans", sans-serif;
}
.article {
  font-weight: 700;
}

.articlesHolder {
  width: 80vw;
  height: 80%;
  background-color: rgba(192, 192, 192, 0.425);
  backdrop-filter: blur(19px);
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.btn {
  width: 80%;
  height: 3rem;
  padding: 10px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: rgba(192, 192, 192, 0.644);
  backdrop-filter: blur(19px);
  font-weight: 700;
  color: #302e4d;
  border: 1px solid #302e4d;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
.btn:hover {
  background-color: #302e4d;
  color: silver;
  backdrop-filter: blur(19px);
  animation: hoverB 1s ease-in-out forwards;
}
.disable {
  cursor: not-allowed !important;
  background-color: rgba(233, 150, 122, 0.63) !important;
  backdrop-filter: blur(6px);
  animation: hoverv 1s ease-in-out forwards;
  animation-iteration-count: infinite;
}
@keyframes hoverv {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.003);
  }
  40% {
    transform: scale(1.006);
  }
  50% {
    transform: scale(1.01);
  }
  60% {
    transform: scale(1.006);
  }
  80% {
    transform: scale(1.003);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hoverB {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.01);
  }
  40% {
    transform: scale(1.02);
  }
  50% {
    transform: scale(1.03);
  }
  60% {
    transform: scale(1.02);
  }
  80% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 300px) {
  .articlesHolder {
    width: 95vw;
    height: 80%;
  }
  .btn {
    width: 95%;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .articlesHolder {
    width: 93vw;
    height: 80%;
  }
  .btn {
    width: 93%;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .articlesHolder {
    width: 92vw;
    height: 80%;
  }
   .btn {
  width: 92%;}
}
@media (min-width: 500px) and (max-width: 600px) {
  .articlesHolder {
    width: 91vw;
    height: 80%;
  }
   .btn {
  width: 91%;}
}

@media (min-width: 600px) and (max-width: 820px) {
}

@media (min-width: 820px) and (max-width: 970px) {
}

@media (min-width: 970px) and (max-width: 1000px) {
}
