.container {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  z-index: 111111;
  top: 1;
  right: 0;
  margin-right: 1rem;
  margin-top: 1rem;
  animation: container 1S ease-out forwards;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: silver;
  margin: 6px 0;
  transition: 0.4s;

}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

@keyframes container {
  0% {
    opacity: 0;
    transform: translateY(-3rem);
  }
  50% {
    opacity: 0.5;
    transform: translateY(0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }

}

