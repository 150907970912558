

.cardHolder{
    width: 40vw;
    min-width: 40vw;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.cardHolder::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}


.imageHolder{
    width: 100%;
    height: 60%;
    max-height: 60%;
    overflow: hidden;
}
.imageHolder img{
      width: 90%;
  height: 100%;
object-fit: fill;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.descriptionHolder{
    width: 90%;
    max-width: 100%;
}

.description {
  font-weight: 400;
  color: #302e4d;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  overflow: hidden;
  line-height: 2rem;
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}
.btnHolder {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: rgba(192, 192, 192, 0.644);
  backdrop-filter: blur(19px);
  font-weight: 700;
  color: #302e4d;
  border: 1px solid #302e4d;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
.btn:hover {
  background-color: #302e4d;
  color: silver;
  backdrop-filter: blur(19px);
  animation: hoverB 1s ease-in-out forwards;
}


@keyframes hoverB {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.01);
  }
  40% {
    transform: scale(1.02);
  }
  50% {
    transform: scale(1.03);
  }
  60% {
    transform: scale(1.02);
  }
  80% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
.cardHolder{
    width: 90vw;
    min-width: 80vw;
    height: 50%;
}
.imageHolder{
  height: 40%;
}

}