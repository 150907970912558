.mainContainer {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  overflow: hidden;
  
}

.videoBg {  
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}

.gb{
  background-color: #302e4d;
}