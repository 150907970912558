.container {
  width: 100%;
  min-width: auto;
  height: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(58, 58, 58, 0.13);
  backdrop-filter: blur(12px);
  margin: 0;
  padding: 0%;
  overflow-y: scroll;
}
.wrapper {
  background-color: rgba(192, 192, 192, 0.445);
  backdrop-filter: blur(12px);
  width: 80%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 6rem auto;
  padding: 2rem;
  overflow: visible;
}
.videoContainer {
  width: 65%;
  max-width: 68%;
  height: 26rem;
  overflow: hidden;
}
.videoBg {
  width: 100% !important;
  height: 100% !important;
  object-fit: fill;
}
.descriptionContainer {
  width: 30%;
  max-width: 30%;
  height: 70%;
  max-height: 70%;
  text-align: center;
  padding: 2.5%;
  overflow-y: scroll;
}
.descriptionContainer::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.descriptionContainer h1 {
  font-weight: 700;
  color: #302e4d;
  font-family: "Open Sans", sans-serif;
}
.descriptionContainer p {
  font-weight: 600;
  color: #302e4d;
  font-family: "Open Sans", sans-serif;
}
.mobileImage {
  width: 20rem;
  max-width: 20rem;
  height: 40rem;
  max-height: 50%;
  padding-top: 2.5%;
  position: relative;
  margin: auto;
}
.mobileImage img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.desktopImage {
  width: 44rem;
  max-width: 46rem;
  height: 22rem;
  max-height: 50%;
  position: relative;
  padding-top: 2.5%;
  margin: auto;
}
.desktopImage img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btnchange {
  padding: 10px;
  width: 2rem;
  height: 1rem;
  cursor: pointer;
  background-color: #302e4d;
  color: silver;
}
.prev {
  position: absolute;
  z-index: 11;
  left: 0;
  margin: 10px;
}
.next {
  position: absolute;
  z-index: 11;
  right: 0;
  margin: 10px;
}
@media screen and (max-width: 300px) {
  .container {
    width: 100%;
    overflow: scroll;
  }
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
  }

  .videoContainer {
    width: 90%;
    max-width: 90%;
    height: 10rem;
    overflow: hidden;
    margin-top: 1rem;
  }
  .descriptionContainer {
    width: 90%;
    max-width: 90%;
  }
  .mobileImage {
    width: 10rem;
    max-width: 20rem;
    height: 20rem;
  }
  .desktopImage {
    width: 20rem;
    height: 10rem;
    padding-top: 9.5%;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 300px) and (max-width: 500px) {
  .container {
    width: 100%;
    overflow: scroll;
  }
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
  }

  .videoContainer {
    width: 90%;
    max-width: 90%;
    height: 15rem;
    overflow: hidden;
    margin-top: 1rem;
  }
  .descriptionContainer {
    width: 90%;
    max-width: 90%;
  }
  .mobileImage {
    width: 16rem;
    height: 30rem;
  }
  .desktopImage {
    width: 24rem;
    height: 15rem;
    padding-top: 9.5%;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 500px) and (max-width: 770px) {
  .container {
    width: 100%;
    overflow: scroll;
  }
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
  }

  .videoContainer {
    width: 90%;
    max-width: 90%;
    height: 25rem;
    overflow: hidden;
    margin-top: 1rem;
  }
  .descriptionContainer {
    width: 90%;
    max-width: 90%;
  }
  .mobileImage {
    width: 16rem;
    height: 30rem;
  }
  .desktopImage {
    width: 35rem;
    height: 20rem;
    padding-top: 9.5%;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 770px) and (max-width: 900px) {
  .desktopImage {
    width: 26rem;
    height: 15rem;
    margin: auto;
  }
  .mobileImage {
    width: 12rem;
    height: 25rem;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .desktopImage {
    width: 30rem;
    height: 20rem;
    margin: auto;
  }
  .mobileImage {
    width: 13rem;
    height: 30rem;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .desktopImage {
    width: 36rem;
    margin: auto;
  }
  .mobileImage {
    width: 14rem;
    height: 30rem;
  }
}
@media (min-width: 1100px) and (max-width: 1200px) {
  .desktopImage {
    width: 38rem;
    margin: auto;
  }
  .mobileImage {
    width: 15rem;
    height: 30rem;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .desktopImage {
    width: 40rem;
    margin: auto;
  }
}
