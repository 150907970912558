
.mainContainer {
  scroll-behavior: smooth;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
}
.wrapper {
  width: auto;
  height: auto;
  background-color: rgba(192, 192, 192, 0.014);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.videoBg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}
.rows {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8rem auto;
}
.rows h1 {
  color: #302e4d;
  font-family: "Open Sans", sans-serif;
}
.portfolio {
  font-weight: 700;
}
.projectHolder{
   width: 95vw;
  height: 90vh;
  display:flex;
  align-items: center;
  background-color: rgba(192, 192, 192, 0.212);
  backdrop-filter: blur(30px);
  overflow-x: scroll;
  scroll-behavior: smooth;

}


.projectHolder::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.projectHolder::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(48, 47, 47); 
  border-radius: 10px;
}
 
/* Handle */
.projectHolder::-webkit-scrollbar-thumb {
  background: silver; 
  border-radius: 10px;
}

/* Handle on hover */
.projectHolder::-webkit-scrollbar-thumb:hover {
  background: #302e4d; 
}



.btn {
  width: 30vh;
  height: 99%;
  padding: 10px;
  cursor: pointer;
  background-color: rgba(192, 192, 192, 0.644);
  backdrop-filter: blur(19px);
  font-weight: 700;
  color: #302e4d;
  border: 1px solid #302e4d;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
.btn:hover {
  background-color: #302e4d;
  color: silver;
  backdrop-filter: blur(19px);
  animation: hoverB 1s ease-in-out forwards;
}
.disable {
  cursor: not-allowed !important;
  background-color: rgba(233, 150, 122, 0.63) !important;
  backdrop-filter: blur(6px);
  animation: hoverv 1s ease-in-out forwards;
  animation-iteration-count: infinite;
}
@keyframes hoverv {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.003);
  }
  40% {
    transform: scale(1.006);
  }
  50% {
    transform: scale(1.01);
  }
  60% {
    transform: scale(1.006);
  }
  80% {
    transform: scale(1.003);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hoverB {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.01);
  }
  40% {
    transform: scale(1.02);
  }
  50% {
    transform: scale(1.03);
  }
  60% {
    transform: scale(1.02);
  }
  80% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}


@media screen and (max-width: 700px) {
  .projectHolder{
flex-wrap: wrap;
height: auto;
}
.btn {
    margin: auto;
    width: 86%;
    height: 11%;
}

}