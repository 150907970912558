@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sc-title {
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}
.pdd {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 15px;
}
.sc-title h1 {
  font-size: 35px;
  margin: 0;
  position: relative;
  font-family: "Open Sans", sans-serif;
  color: silver;
}

.sc-title h1 b:last-child {
  color: #1a1a2e;
}

.about-cont {
  width: 60vw;
  overflow: hidden;
  height: auto;
  background-color: rgba(192, 192, 192, 0.425);
  backdrop-filter: blur(9px);
  padding: 1rem 0;
}
.about-cont .about-ctn-text {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.about-cont .about-ctn-text p {
  font-size: 16px;
  line-height: 25px;
  color: #1a1a2e;
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

.about-cont .about-ctn-text p mark {
  background-color: silver;
  padding: 2.5px;
}
.rows {
  display: flex;
  margin-bottom: 6rem;
}

/* About Me Info */

.personal-info {
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 50%;
  margin-top: 15px;
  background-color: rgba(192, 192, 192, 0.425);
  backdrop-filter: blur(9px);
}
.personal-info .info {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  border-bottom: 1px solid #d3c8e7;
}
.personal-info .info p {
  font-weight: 600;
  padding: 10px 0;
  font-size: 16px;
  color: #302e4d;
  font-family: "Open Sans", sans-serif;
}
.personal-info .info p span {
  font-weight: 400;
  margin-left: 4px;
  display: inline-block;
}

/* Aboute Me Skills */

.skills {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 45%;
  margin-top: 15px;
  margin-left: 1px;
  background-color: rgba(192, 192, 192, 0.425);
  backdrop-filter: blur(9px);
  padding: 0 1.5rem;
}
.skills .skills-info {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 3.2rem;
}
.skills .skills-info h5 {
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  color: #302e4d;
  text-transform: capitalize;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
.skills .skills-info .progress {
  background-color: silver;
  height: 8px;
  border-radius: 4px;
  position: relative;
  width: 100%;
}
.skills .skills-info {
  margin-bottom: 10px;
}
.skills .skills-info .progress .progress-in {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 4px;
  background-color: #302e4d;
}
.skills .skills-info .sk-perc {
  line-height: 40%;
  font-weight: 400;
  position: absolute;
  right: 0;
  top: -20px;
  color: #302e4d;
}

@media screen and (max-width: 300px) {
  .about-cont {
    width: 90vw;
    padding: 0rem 0;
  }
  .about-cont .about-ctn-text {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* About Me Info */

  .personal-info {
    max-width: 90%;
    margin-top: 15px;
    overflow: hidden;
    padding: 0;
  }

  /* Aboute Me Skills */

  .skills {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 90%;
    margin-top: 15px;
    margin-left: 0px;
    padding: 0 5%;
    width: 80%;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .about-cont {
    width: 90vw;
    padding: 0rem 0;
  }
  .about-cont .about-ctn-text {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* About Me Info */

  .personal-info {
    max-width: 90%;
    margin-top: 15px;
    overflow: hidden;
    padding: 0;
  }

  /* Aboute Me Skills */

  .skills {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 90%;
    margin-top: 15px;
    margin-left: 0px;
    padding: 0 5%;
    width: 80%;
    padding-bottom: 1rem;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
     .about-cont {
    width: 90vw;
    padding: 0rem 0;
  }
  .about-cont .about-ctn-text {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* About Me Info */

  .personal-info {
    max-width: 90vw;
    margin-top: 15px;
    overflow: hidden;
    padding: 0;
    width: 90vw;

  }

  /* Aboute Me Skills */

  .skills {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 90vw;
    margin-top: 15px;
    margin-left: 0px;
    padding: 0 5vw;
    width: 80vw;
    padding-bottom: 1rem;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
     .about-cont {
    width: 90vw;
    padding: 0rem 0;
  }
  .about-cont .about-ctn-text {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* About Me Info */

 
  .personal-info {
    max-width: 90vw;
    margin-top: 15px;
    overflow: hidden;
    padding: 0;
    width: 90vw;

  }

  /* Aboute Me Skills */

  .skills {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 90vw;
    margin-top: 15px;
    margin-left: 0px;
    padding: 0 5vw;
    width: 80vw;
    padding-bottom: 1rem;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
     .about-cont {
    width: 90vw;
    padding: 0rem 0;
  }
  .about-cont .about-ctn-text {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* About Me Info */

  .personal-info {
    max-width: 90vw;
    margin-top: 15px;
    overflow: hidden;
    padding: 0;
    width: 90vw;

  }

  /* Aboute Me Skills */

  .skills {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 90vw;
    margin-top: 15px;
    margin-left: 0px;
    padding: 0 5vw;
    width: 80vw;
    padding-bottom: 1rem;
  }
}
@media (min-width: 800px) and (max-width: 900px) {
    .about-cont {
    width: 90vw;
    padding: 0rem 0;
  }
  
}


@media (min-width: 900px) and (max-width: 1000px) {
       .about-cont {
    width: 90vw;
    padding: 0rem 0;
  }
  
}

@media (min-width: 1000px) {
       .about-cont {
    width: 770px;
    padding: 0rem 0;
  }
  
}


