@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");


.mainContainer {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
}
 .wrapper{
    width: 100vw;
    height: auto;
     background-color: rgba(192, 192, 192, 0.014);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


    }
.pdd {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.videoBg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}
.rows{
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 8rem auto;
    
}

.firstChild{
  margin-right: 15px;
}

.education{
    max-width: 40%;
    width: 50vw;
  animation: dkhla 3s ease-out forwards;
  overflow: hidden;
}
.tm-box{
  width: 100%;

}

h3.titre{
    font-size: 24px;
    font-weight: 700;
    color:#302e4d;
  font-family: "Open Sans", sans-serif;

}

.timeline{
    background-color: rgba(192, 192, 192, 0.425) ;
    backdrop-filter: blur(19px) ;
    padding: 30px 30px;
    width: 100%;
    margin-top: 25px;
    position: relative;
}

@keyframes dkhla {
  from {
    opacity: 0;
    transform: translateX(50rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}





@media screen and (max-width: 300px) {
    .wrapper{
        width: 100vw;
    height: auto;
    

    }
    .rows{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     margin: 4rem 0;
}
.education{
    max-width: 100%;
    width: 75vw;
    overflow: hidden;
}
h3.titre{
    font-size: 30px;
    font-weight: 700;

}
.timeline{
    padding: 30px 5px;
    width: 100%;

}

}

@media (min-width: 300px) and (max-width: 400px) {
   .wrapper{
        width: 100vw;
    height: auto;
    

    }
    .rows{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     margin: 4rem 0;
}
.education{
    max-width: 100%;
    width: 75vw;
    overflow: hidden;
}
h3.titre{
    font-size: 35px;
    font-weight: 700;

}
.timeline{
    padding: 30px 15px;
    width: 100%;

}


}
@media (min-width: 400px) and (max-width: 500px) {
     .wrapper{
        width: 100vw;
    height: auto;
    

    }
    .rows{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     margin: 4rem 0;
}
.education{
    max-width: 100%;
    width: 75vw;
    overflow: hidden;
}
h3.titre{
    font-size: 40px;
    font-weight: 700;

}
.timeline{
    padding: 30px 20px;
    width: 100%;

}


}
@media (min-width: 500px) and (max-width: 600px) {
    .wrapper{
        width: 100vw;
    height: auto;
    

    }
    .rows{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     margin: 4rem 0;
}
.education{
    max-width: 100%;
    width: 75vw;
    overflow: hidden;
}
h3.titre{
    font-size: 45px;
    font-weight: 700;

}
.timeline{
    padding: 30px 25px;
    width: 100%;

}
}



@media (min-width: 600px) and (max-width: 820px) {
    .wrapper{
        width: 100vw;
    height: auto;
    

    }
    .rows{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     margin: 4rem 0;
}
.education{
    max-width: 100%;
    width: 75vw;
    overflow: hidden;
}
h3.titre{
    font-size: 60px;
    font-weight: 700;

}
.timeline{
    padding: 30px 25px;
    width: 100%;

}
}




@media (min-width: 820px) and (max-width: 970px) {
   .wrapper{
        width: 90vw;
        height: auto;
    }
    .timeline{
    padding: 30px 20px;
    width: 100%;
    margin-top: 25px;
    position: relative;
}
}














@media (min-width: 970px) and (max-width: 1000px) {

}
