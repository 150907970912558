@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.articleCard {
  width: 30%;
  height: auto;
  min-height: 18rem;
  max-height: 25rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
}

.articleCard img {
  width: 100%;
  height: 100%;
object-fit: fill;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.425);
  backdrop-filter: blur(12px);
  display: block;
  margin: auto;
  animation: dakhla 1s ease-in-out forwards;
  overflow: hidden;
  padding: 0 10px;
}
.overlay p {
  font-weight: 400;
  color: #302e4d;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  overflow: hidden;
  line-height: 2rem;
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}
.overlay h1 {
  text-align: center;
  font-weight: 700;
  color: #302e4d;
  font-family: "Open Sans", sans-serif;
}
.btnHolder {
  width: inherit;
  display: flex;
  justify-content: center;
}
.btn {
  width: 90%;
  padding: 10px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: rgba(192, 192, 192, 0.644);
  backdrop-filter: blur(19px);
  font-weight: 700;
  color: #302e4d;
  border: 1px solid #302e4d;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
.btn:hover {
  background-color: #302e4d;
  color: silver;
  backdrop-filter: blur(19px);
  animation: hoverB 1s ease-in-out forwards;
}

@keyframes dakhla {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  20% {
    opacity: 0.2;
    transform: scale(0.9);
  }
  40% {
    opacity: 0.4;
    transform: scale(0.95);
  }
  60% {
    opacity: 0.6;
    transform: scale(1);
  }
  80% {
    opacity: 0.8;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hoverB {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.01);
  }
  40% {
    transform: scale(1.02);
  }
  50% {
    transform: scale(1.03);
  }
  60% {
    transform: scale(1.02);
  }
  80% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 300px) {
  .articleCard {
    width: 100%;
    height: 30rem;
    min-height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    margin-bottom: 1rem;
  }
  .articleCard img {
    width: 100%;
    height: 50%;
    min-height: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .overlay {
    width: 100%;
    height: 50%;
    animation: dakhla 1s ease-in-out forwards;
    padding: 0 2px;
  }
  .overlay h1 {
    font-size: 25px;
  }
  .overlay p {
    margin-bottom: 3rem;
    margin-right: 5px;
    -webkit-line-clamp: 2;
  }
  .btn {
    margin-right: 10px;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .articleCard {
    width: 100%;
    height: 30rem;
    min-height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    margin-bottom: 1rem;
  }
  .articleCard img {
    width: 100%;
    height: 50%;
    min-height: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .overlay {
    width: 100%;
    height: 50%;
    animation: dakhla 1s ease-in-out forwards;
    padding: 0 5px;
  }
  .overlay h1 {
    font-size: 25px;
  }
  .overlay p {
    margin-bottom: 3rem;
    margin-right: 5px;
    -webkit-line-clamp: 2;
  }
  .btn {
    margin-right: 10px;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .articleCard {
    width: 100%;
    height: 30rem;
    min-height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    margin-bottom: 1rem;
  }

  .articleCard img {
    width: 100%;
    height: 50%;
    min-height: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .overlay {
    width: 100%;
    height: 50%;
    animation: dakhla 1s ease-in-out forwards;
    padding: 0 5px;
  }
  .overlay p {
    font-weight: 600;
    color: #302e4d;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    margin-bottom: 3rem;
    margin-right: 5px;
    -webkit-line-clamp: 2;
  }
  .btn {
    margin-right: 10px;
  }
}
@media (min-width: 500px) and (max-width: 600px) {
  .articleCard {
    width: 100%;
    height: 30rem;
    min-height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    margin-bottom: 1rem;
  }
  .articleCard img {
    width: 100%;
    height: 50%;
    min-height: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .overlay {
    width: 100%;
    height: 50%;
    animation: dakhla 1s ease-in-out forwards;
    padding: 0 5px;
  }
  .overlay p {
    margin-bottom: 3rem;
    margin-right: 5px;
    -webkit-line-clamp: 2;
  }
  .btn {
    margin-right: 10px;
  }
}

@media (min-width: 600px) and (max-width: 820px) {
  .articleCard {
    width: 100%;
    height: 30rem;
    min-height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    margin-bottom: 1rem;
  }
  .articleCard img {
    width: 100%;
    height: 50%;
    min-height: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .overlay {
    width: 100%;
    height: 50%;
    animation: dakhla 1s ease-in-out forwards;
    padding: 0 0;
  }
  .overlay p {
    margin-bottom: 3rem;
    margin-right: 5px;
    -webkit-line-clamp: 2;
  }
  .btn {
    margin-right: 10px;
  }
}
